<template>
  <div v-if="ads?.length" class="max-w-screen-xl">
    <EStoreSwiper id="secondary-swiper" swiper-pagination :items="ads">
      <template #slide="{ item }">
        <AdSlide
          :key="item.id"
          :ad="item"
          :width="240"
          :height="isMobileOrTablet ? 60 : 40"
        />
      </template>
    </EStoreSwiper>
  </div>
</template>

<script setup lang="ts">
const { isMobileOrTablet } = useDevice()

const { ads } = useAnnouncements()
</script>

<style>
#secondary-swiper > .swiper > .swiper-pagination.main-pagination {
  @apply top-auto bottom-4;
}
</style>
